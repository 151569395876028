<!-- Title -->
<div class="mb-2 text-2xl font-extrabold tracking-tight leading-tight">Sign in</div>
<p class="text-md">{{ 'AUTH.SIGNIN.INFO' | transloco }}</p>

<!-- Alert -->



<!-- Sign in form -->
<form class="mt-8" [formGroup]="signInForm" #signInNgForm>

    <!-- username field -->
    <mat-form-field class="w-full mb-8">
        <mat-label>E-Mail / Username</mat-label>
        <input id="username" matInput [formControlName]="'username'">
        <mat-error *ngIf="signInForm.get('username')?.hasError('required')">
            This Field is required
        </mat-error>
    </mat-form-field>

    <!-- Password field -->
    <mat-form-field class="w-full mb-8">
        <mat-label>Password</mat-label>
        <input id="password" matInput type="password" [formControlName]="'password'" #passwordField>
        <mat-icon class="icon-size-5" matSuffix
            (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
            [svgIcon]="passwordField.type === 'password' ? 'heroicons_outline:eye' : 'heroicons_outline:eye-slash'">
        </mat-icon>
        <mat-error>
            Password is required
        </mat-error>
    </mat-form-field>

    <!-- Actions -->
    <div class="inline-flex items-center justify-between w-full mt-1.5">
        <!-- <mat-slide-toggle class="" [formControlName]="'rememberMe'">
            Remember me
        </mat-slide-toggle> -->
        <span class="flex-grow"></span>
        <a class="text-sm font-normal hover:underline" [routerLink]="['/forgot-password']">Forgot password?
        </a>
    </div>

    <!-- Submit button -->
    <button class="mat-button-large w-full mt-6" mat-flat-button color="primary" [disabled]="signInForm.disabled"
        (click)="signIn()">
        <span *ngIf="!signInForm.disabled">
            Sign in
        </span>
        <mat-progress-spinner *ngIf="signInForm.disabled" [diameter]="24"
            [mode]="'indeterminate'"></mat-progress-spinner>
    </button>
</form>