import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TransactionService } from 'app/service/transaction/transaction.service';
import { TransactionSourceService } from 'app/service/transactionSource/transactionSource.service';
import { formatNumber } from 'app/utils/formatter.utils';

@Component({
  selector: 'app-imported-files',
  standalone: true,
  imports: [AsyncPipe],
  templateUrl: './imported-files.component.html',
  styleUrl: './imported-files.component.scss'
})
export class ImportedFilesComponent {
  transactionCount: number = 0;
  sourceFileCount: number = 0;
  txSourceService = inject(TransactionSourceService);
  txService = inject(TransactionService);


  constructor(){}

  formatNumber = formatNumber;
}
