<mat-card>
    <mat-card-header>
        <mat-card-title>{{ "INVITE.HEADLINE" | transloco:{company: invitation.company?.info!.name || 'NTRDE'}
            }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ng-container *ngIf="isValidInvite(); else profileError">
            <div class="flex flex-col gap-2">
                <div>{{ "INVITE.BODY" | transloco: { name: invitation.invitedBy?.firstName ||
                    invitation.invitedBy?.username + ' ' + (invitation.invitedBy?.lastName || ''),company:
                    invitation.company?.info!.name || 'NTRDE'} }}</div>
                <div>{{"INVITE.SET_PASSWORD" | transloco}}</div>
                <form class="flex flex-col gap-2" [formGroup]="profileForm">
                    <mat-form-field>
                        <mat-label for="firstname">{{"INVITE.FORM.FIRSTNAME" | transloco}}</mat-label>
                        <input matInput formControlName="firstname" autocomplete="name">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label for="lastname">{{"INVITE.FORM.LASTNAME" | transloco}}</mat-label>
                        <input matInput formControlName="lastname" autocomplete="family-name">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label for="password">{{"INVITE.FORM.PASSWORD" | transloco}}</mat-label>
                        <input matInput formControlName="password" [type]="hide ? 'password' : 'text'"
                            placeholder="{{'INVITE.FORM.PASSWORD' | transloco}}" name="password" id="password"
                            autocomplete="new-password">
                        <button mat-icon-button matSuffix (click)="hide = !hide" tabindex="-1">
                            <mat-icon
                                [svgIcon]="hide ? 'heroicons_outline:eye' : 'heroicons_outline:eye-slash'"></mat-icon>
                        </button>
                        <mat-error *ngIf="password?.errors?.['minlength']">{{"INVITE.ERROR.MINLENGTH" |
                            transloco}}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label for="passwordRepeat">{{"INVITE.FORM.PASSWORD_REPEAT" | transloco}}</mat-label>
                        <input matInput formControlName="passwordRepeat" [type]="hide ? 'password' : 'text'"
                            placeholder="{{'INVITE.PASSWORD_CONFIRM' | transloco}}" name="passwordRepeat"
                            onpaste="return false;" ondrop="return false;">
                        <button mat-icon-button matSuffix (click)="hide = !hide" tabindex="-1">
                            <mat-icon
                                [svgIcon]="hide ? 'heroicons_outline:eye' : 'heroicons_outline:eye-slash'"></mat-icon>
                        </button>
                        <mat-error *ngIf="profileForm.hasError('passwordMatch')">{{"INVITE.ERROR.PASSWORDMATCH" |
                            transloco}}
                        </mat-error>
                    </mat-form-field>
                </form>
            </div>
        </ng-container>
        <ng-template #profileError>
            <div class="py-4">{{ "INVITE.ERROR.ALREADY_REGISTERED" | transloco:{company: profile?.company?.info?.name}
                }}</div>
            <div>or <a [routerLink]="[ '/sign-in']" class="underline">Log in</a></div>
        </ng-template>


    </mat-card-content>
    <mat-card-actions *ngIf="!profile" class="flex gap-2">
        <div class="flex-grow"></div>
        <ng-container *ngIf="isValidInvite(); else elseBlock">
            <button mat-stroked-button color="warn" (click)="decline()">{{"INVITE.DECLINE" | transloco}}</button>
            <button mat-flat-button color="primary" (click)="submit()">{{"INVITE.SUBMIT" | transloco}}</button>
        </ng-container>
        <ng-template #elseBlock></ng-template>
    </mat-card-actions>
</mat-card>