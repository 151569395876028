import { AsyncPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { User } from '@yukawa/chain-main-angular-core';
import { UserService } from 'app/service/user/user.service';
import { UserInfo } from 'app/types/domain/profile';
import { ChemUser } from 'app/types/profile.types';

import { Subject, takeUntil } from 'rxjs';


@Component({
  selector: 'app-user',
  standalone: true,
  imports: [
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    AsyncPipe,
    RouterModule
  ],
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss'
})
export class UserComponent implements OnInit, OnDestroy{
  username: string | undefined;

  #unsubscribeAll: Subject<any> = new Subject();

  constructor(private _userService:UserService){
    
  }

  ngOnInit(): void {
    this._userService.user$.pipe(
      takeUntil(this.#unsubscribeAll)
    ).subscribe(
      (user:ChemUser) => this.username = user.username
    )
  }

  ngOnDestroy(): void { 
    this.#unsubscribeAll.next(null);
    this.#unsubscribeAll.complete();
  }


}
