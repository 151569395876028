<div class="flex flex-col gap-6 mt-4">
    <div class="flex gap-4 items-center">
        <span class="text-xl font-extrabold">{{ formatNumber((txService.totalRows | async) || 0) }}</span>
        <span>Total Transactions</span>
    </div>

    <div class="flex gap-4 items-center">
        <span class="text-xl font-extrabold">{{ txSourceService.totalRows | async}}</span>
        <span>Imported Files</span>
    </div>

</div>