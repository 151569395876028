import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { FavService } from 'app/service/favorites/fav.service';

@Component({
  selector: 'app-favorites',
  standalone: true,
  imports: [
    CommonModule,
    MatAccordion,
    MatButtonModule,
    MatChipsModule,
    MatTabsModule,
    MatListModule,
    MatIconModule,
    MatExpansionModule,
    AsyncPipe,
    TranslocoModule,
    RouterModule
  ],
  templateUrl: './favorites.component.html',
  styleUrl: './favorites.component.scss'
})
export class FavoritesComponent implements OnInit{
  favData:any;

  constructor(public favService: FavService, private _ref:ChangeDetectorRef) {}

  ngOnInit() {
    this.favService.queryFavCorps();
  }
}
