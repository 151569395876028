import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { AuthService } from 'app/service/auth.service';
import { finalize, tap } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    CommonModule,
    RouterModule,
    TranslocoModule,
    MatProgressSpinnerModule,
    MatIconModule
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent implements OnInit{
  resetForm!: UntypedFormGroup
  token!: string;
  showAlert: boolean = false;
  alert: { type: string; message: string } = {
    type: 'success',
    message: '' 
  };

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _authService: AuthService,
    private _route : ActivatedRoute,
    private _router: Router
  ){
    this.token = this._route.snapshot.queryParams['token'];
  } 

  ngOnInit(): void {
    this.resetForm = this._formBuilder.group({
      password: ['',[Validators.required]],
      passwordRepeat: ['',[Validators.required]]
    })


  }

  resetPassword(): void {
    if (this.resetForm.invalid) {
      return;
    }
    this.resetForm.disable();
    this._authService.resetPassword(this.resetForm.value.password, this.token)
    .pipe(
      finalize(() => {
        this.resetForm.enable();
        this.showAlert = true;
      })
    )
    .subscribe({
      next: (response) => {
        console.log('Password reset successful', response);
        this.alert = {
          type: 'success',
          message: 'Password reset successful'
        }
        this.showAlert = true;
        this.resetForm.reset();
        setTimeout(()=>{
          this._router.navigate(['/login']);
        },3000)
      },
      error: () => {
        this.resetForm.enable();
      }
    })
  }
}


//https://chem.dev.cloud.yukawa.de/reset-password?token=eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhbGV4bWVyc2RvcmZAZ21haWwuY29tIiwiaWF0IjoxNzI5MTkxMTg0LCJleHAiOjE3MjkxOTQ3ODQsInNjb3BlIjpbIlJPTEVfVVNFUl9FRElUX09XTl9QQVNTV09SRCJdfQ.O4F_kgjgt7E_L7m6kDd3pkdxNCGdndsMPLRk_GOMSJg