<mat-tab-group>
    <mat-tab class="fav-tab">
        <ng-template mat-tab-label><span class="font-bold">{{'FAVORITES.FILTER' | transloco}}</span></ng-template>

        <div class="py-4 flex flex-col gap-2 items-start">
            @for(filter of (favService.favFilters | async) | slice:0:5; track filter){
            <a mat-stroked-button color="accent" [routerLink]="[ '/trade']" [queryParams]="filter.filter"
                class="filterButton">{{filter.name}}</a>
            }

        </div>
        <button mat-button [routerLink]="['/settings/favorites/filters']">{{
            "SEE_ALL" | transloco }}
            <mat-icon svgIcon="mat_outline:arrow_forward"></mat-icon>
        </button>

    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>{{'FAVORITES.CORP' | transloco}}</ng-template>

        <mat-list class="py-4 flex gap-2 ">
            @for(corp of (favService.favCorps | async) | slice:0:5; track corp){
            <mat-list-item>
                <mat-icon matListItemIcon svgIcon="mat_outline:apartment"></mat-icon>
                <div matListItemTitle>{{corp.name}}</div>
            </mat-list-item>
            }
        </mat-list>
        <button mat-button [routerLink]="['/settings/favorites/companies']">{{
            "SEE_ALL" | transloco }}
            <mat-icon svgIcon="mat_outline:arrow_forward"></mat-icon>
        </button>

    </mat-tab>

</mat-tab-group>