<div class="grid grid-cols-3 m-4 gap-4">
  @for (card of cards | async; track card) {
  <div class="lg:col-span-1 col-span-3">
    <mat-card class="dashboard-card h-full">
      <mat-card-header>
        <mat-card-title class="p-2">
          <div *ngIf="card.icon" class="icon-wrapper m-auto flex rounded-md p-2">
            <mat-icon [svgIcon]="card.icon || '' "></mat-icon>
          </div>
          <span>{{card.title}}</span>
          <!--button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
              <mat-icon svgIcon="more_vert"></mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              CARD MENU GOES HERE
            </mat-menu-->
        </mat-card-title>
      </mat-card-header>
      @if(card.component){
      <mat-card-content class="dashboard-card-content h-full">
        <ng-container *ngComponentOutlet="card.component; inputs:card.componentInputs"></ng-container>
      </mat-card-content>
      }
    </mat-card>
  </div>
  }
</div>