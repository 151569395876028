import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { InvitationService } from 'app/service/invitation/invitation.service';
import { Invitation, InvitationStatus, UserInfo } from 'app/types/domain/profile';

@Component({
  selector: 'app-invitation',
  standalone: true,
  imports: [
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    CommonModule,
    FormsModule,
    TranslocoModule,
    ReactiveFormsModule,
    RouterModule
  ],
  templateUrl: './invitation.component.html',
  styleUrl: './invitation.component.scss'
})
export class InvitationComponent implements OnInit {
  _inviteService = inject(InvitationService);

  profile?:UserInfo;
  invitation!:Invitation;
  private _token: string = '';
  hide:boolean = true;
  profileForm:FormGroup = new FormGroup({
    firstname: new FormControl('',[Validators.required]),
    lastname: new FormControl('',[Validators.required]),
    password : new FormControl('',[Validators.required, Validators.minLength(8)]),
    passwordRepeat : new FormControl('',[Validators.required, ])
  })
  constructor(
    private _route: ActivatedRoute,
    private _router: Router
  ) { }

  get password(){
    return this.profileForm.get('password');
  }

  get passwordRepeat(){
    return this.profileForm.get('passwordRepeat');
  }

  ngOnInit(): void {
    console.log(this._route.snapshot.data);
    const routeData = this._route.snapshot.data['invite'];
    this._token = routeData['token'];
    this.profile = routeData['profile'];
    this.invitation = routeData['invitation'];

    if(this._token === undefined){
      this._router.navigate(['/']);
    }
  }
  isValidInvite(){
    return !this.profile && this.invitation.status !== InvitationStatus.ACCEPTED;
  }


  submit(){
    if(this.profileForm.valid){
     if(this.password!.value !== this.passwordRepeat!.value){
      this.passwordRepeat!.setErrors({passwordMatch: true});
      this.password!.setErrors({passwordMatch: true});
      this.profileForm.setErrors({passwordMatch: true});
      return;
     }
     this._inviteService.acceptInvitation(this._token, this.profileForm.value)
     .subscribe((result)=>{
        this._router.navigate(['/settings/account/profile']);
     });
    }
  }
  decline(){
    this._inviteService.declineInvitation(this._token);
  }

}
