import { Component, OnInit, Type, inject } from '@angular/core';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { AsyncPipe, CommonModule, NgComponentOutlet } from '@angular/common';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { TranslocoService } from '@jsverse/transloco';
import { FavoritesComponent } from './favorites/favorites.component';
import { Observable } from 'rxjs';
import { UploadComponent } from '../trade/upload/upload.component';
import { ImportedFilesComponent } from './imported-files/imported-files.component';
import { TransactionService } from 'app/service/transaction/transaction.service';

export interface DashboardCard {
  title: string;
  icon?: string;
  cols?: number;
  rows?: number;
  component?: Type<any>;
  componentInputs?: Record<string, unknown>
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  standalone: true,
  imports: [
    AsyncPipe,
    CommonModule,
    MatGridListModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    NgComponentOutlet
  ]
})
export class DashboardComponent implements OnInit {
  private breakpointObserver = inject(BreakpointObserver);
  private _t = inject(TranslocoService);
  private txService = inject(TransactionService);

  ngOnInit(): void {
    this.txService.queryTransactions();
  }

  /** Based on the screen size, switch from standard to one column per row */
  cards:Observable<DashboardCard[]> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: this._t.translate('DASHBOARD.TRADE'), component: ImportedFilesComponent, icon: 'mat_outline:list_alt' },
          { title: this._t.translate('DASHBOARD.FAVORITE'), cols: 2, rows: 2, component: FavoritesComponent, icon: 'mat_outline:grade' },
          { title: this._t.translate('UPLOAD'), cols: 2, rows: 2, component: UploadComponent, icon: 'mat_outline:cloud_upload' },
        ];
      }

      return [
        { title: this._t.translate('DASHBOARD.TRADE'), component: ImportedFilesComponent, icon: 'mat_outline:list_alt' },
        { title: this._t.translate('DASHBOARD.FAVORITE'), component: FavoritesComponent, icon: 'mat_outline:grade' },
        { title: this._t.translate('UPLOAD'), component: UploadComponent, icon: 'mat_outline:cloud_upload' },
      ];
    })
  );
}
