<!-- Title -->
<div class="mb-2 text-2xl font-extrabold tracking-tight leading-tight">{{ 'AUTH.RESET.HEADLINE' | transloco}}</div>
<p class="text-md">{{ 'AUTH.RESET.INFO' | transloco }}</p>

<!-- Alert -->
<div *ngIf="showAlert" [class]="alert.type" class="my-4 p-4 rounded-md border">
    <div>{{alert.message}}</div>
</div>


<!-- Sign in form -->
<form class="mt-8 flex flex-col gap-4" [formGroup]="resetForm" #resetNgForm>

    <!-- Password field -->
    <mat-form-field class="w-full" appearance="outline">
        <mat-label>Password</mat-label>
        <input id="password" matInput type="password" [formControlName]="'password'" #passwordField>
        <button mat-icon-button type="button"
            (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
            matSuffix [disabled]="resetForm.disabled">
            <mat-icon class="icon-size-5"
                [svgIcon]="passwordField.type === 'password' ? 'heroicons_outline:eye' : 'heroicons_outline:eye-slash'"></mat-icon>
        </button>
        <mat-error>
            Password is required
        </mat-error>
    </mat-form-field>

    <!-- Password repeat field -->
    <mat-form-field class="w-full" appearance="outline">
        <mat-label>repeat Password</mat-label>
        <input id="passwordRepeat" matInput type="password" formControlName="passwordRepeat" #passwordRepeatField>
        <button mat-icon-button type="button"
            (click)="passwordRepeatField.type === 'password' ? passwordRepeatField.type = 'text' : passwordRepeatField.type = 'password'"
            matSuffix [disabled]="resetForm.disabled">
            <mat-icon class="icon-size-5"
                [svgIcon]="passwordRepeatField.type === 'password' ? 'heroicons_outline:eye' : 'heroicons_outline:eye-slash'"></mat-icon>
        </button>
        <mat-error>
            Password repeat is required
        </mat-error>
    </mat-form-field>


    <!-- Submit button -->
    <button class="mat-button-large w-full mt-6" mat-flat-button color="primary" [disabled]="resetForm.disabled"
        (click)="resetPassword()">
        <span *ngIf="!resetForm.disabled">
            {{ 'AUTH.RESET.SUBMIT' | transloco }}
        </span>
        <mat-progress-spinner *ngIf="resetForm.disabled" [diameter]="24"
            [mode]="'indeterminate'"></mat-progress-spinner>
    </button>

    <!-- Actions -->
    <div class="inline-flex items-center gap-2 w-full mt-3 text-sm">
        <div class="mx-auto inline-flex gap-2">
            <a class="font-normal hover:underline" [routerLink]="['/sign-in']">Back to Login
            </a>
        </div>
    </div>
</form>