import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { InvitationService } from 'app/service/invitation/invitation.service';
import { Invitation, UserInfo } from 'app/types/domain/profile';
import { forkJoin, lastValueFrom, of } from 'rxjs';

export const invitationResolver: ResolveFn<{token:string, invitation: Invitation, profile: UserInfo}|boolean> = (route, state) => {
  const _httpClient = inject(HttpClient);
  const _inviteService = inject(InvitationService);
  const _configService = inject(ConfigService);
  const token = route.queryParamMap.get('token');
  if (!token) {
    return false
  }
  
  const invite = lastValueFrom(_inviteService.getInvitation(token));
  const profile = lastValueFrom(_inviteService.getProfile(token));

  return forkJoin({
    token: of(token),
    invitation: invite,
    profile: profile
  })
};
// https://chem.dev.cloud.yukawa.de/web
// /invitation?token=eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhbGV4bWVyc2RvcmZAZ21haWwuY29tIiwiaWF0IjoxNzI4OTAwMjQzLCJleHAiOjE3MzAxMDk4NDMsInNjb3BlIjpbIlJPTEVfSU5WSVRFX0NPTkYiXSwiZGV0YWlscyI6eyJpbnZpdGF0aW9uSWQiOjEwMH19.260x5SVjo3szqCFlxUcU_-1JmmTNMtUgaGNOh3e7Kd8
