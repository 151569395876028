import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, inject } from '@angular/core';
import { provideHttpClient } from '@angular/common/http';
import { provideRouter } from '@angular/router';
import { provideTransloco, TranslocoService } from '@jsverse/transloco';
import { Observable, firstValueFrom } from 'rxjs';
import {
  APP_ENVIRONMENT_TOKEN,
  ConfigService,
  InitService,
  MockApiModule,
} from '@yukawa/chain-base-angular-client';

import { environment } from '../environments';
import { routes } from './app.routes';
import { TranslocoHttpLoader } from './service/transloco.http-loader';
import { provideIcons } from './service/icons/icons.provider';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideCore } from './service/core.provider';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideCore(false),
    provideIcons(),
    provideAnimations(),
    // Set Input apperearance to outline per default
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline', subscriptSizing: 'dynamic'}},
    // Yukawa Environment
    {
      provide: APP_ENVIRONMENT_TOKEN,
      useValue: environment,
    },

    // Yukawa Config
    {
      provide: APP_INITIALIZER,
      useFactory: (myInitService: InitService) => (): Observable<void> =>
          myInitService.initConfigs(),
      deps: [InitService, ConfigService],
      multi: true,
    },

      // Transloco Config
    provideTransloco({
        config: {
            availableLangs: [
                {
                    id: 'en',
                    label: 'English',
                },
            ],
            defaultLang: 'en',
            fallbackLang: 'en',
            reRenderOnLangChange: true,
            prodMode: true,
        },
        loader: TranslocoHttpLoader,
    }),
    {
        // Preload the default language before the app starts to prevent empty/jumping content
        provide: APP_INITIALIZER,
        useFactory: () => {
            const translocoService = inject(TranslocoService);
            const defaultLang = translocoService.getDefaultLang();
            translocoService.setActiveLang(defaultLang);

            return () => firstValueFrom(translocoService.load(defaultLang));
        },
        multi: true,
    }
  ],

};

